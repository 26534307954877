/*
|--------------------
|     PAGE HOME
|--------------------
*/

#partenaires {
  .partenaire {
    height: 22px;
    max-width: initial;
  }
}

#faq {
  .accordion {
    width: 100%;

    .title {
      color: #333333;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      width: 100%;
      border-top: 1px solid #d0eaed;
      padding: 20px 30px;
      display: block;
      font-family: "Cinzel";
      font-size: 19px;
      font-weight: 500;
      text-align: left;
    }

    .contenu {

      p {
        padding: 0 0 15px 0;
      }

      ul {
        li {
          padding: 0 0px 15px 20px;
          position: relative;

          &:before {
            content: "";
            height: 6px;
            width: 6px;
            display: block;
            float: left;
            background-color: #000;
            border-radius: 50%;
            position: absolute;
            top: 7px;
            left: 0;
          }
        }
      }
    }

    img {
      max-width: 100%;
    }

    .inner {
      overflow: hidden;
      display: none;
      margin-right: 0;
      margin-left: 0;
      border-left: 1px solid $blue;
      border-right: 1px solid $blue;
    }

    .toggle {
      position: relative;

      &:after {
        content: "+";
        color: #276f8b;
        display: block;
        position: absolute;
        right: 18px;
        top: 18px;
      }

      &.active {
        color: $blue;
        border-left: 1px solid #276f8b;
        border-right: 1px solid #276f8b;
        border-top: 1px solid #276f8b;

        &:after {
          content: "-";
          color: #276f8b;
          font-size: 32px;
          display: block;
          position: absolute;
          right: 20px;
          top: 13px;
        }
      }
    }

    .inner {
      &.show {
        border-bottom: 1px solid #276f8b;
      }
    }

    .bdr:last-child {
      border-bottom: 1px solid #d0eaed;
    }
  }
}

#news {
  .swiperNews {
    overflow: hidden;
    position: relative;

    h3 {
      font-size: 19px;
    }

    .swiper-button-next,
    .swiper-button-prev {
      color: $blue;
      width: 50px;
      height: 50px;
      border-radius: 50%;

      &:after {
        font-size: 24px;
        border: 1px solid;
        height: 50px;
        width: 50px;
        padding: 13px 19px;
        border-radius: 50%;
        background: white;
      }
    }
    .swiper-button-prev {
      left: inherit;
      right: 77px;
      top: inherit;
      bottom: 0;
      z-index: 11;
    }
    .swiper-button-next {
      left: inherit;
      top: inherit;
      bottom: 0;
      z-index: 11;
    }
  }

  .elem-news {
    width: 100%;

    .img_news {
      width: 100%;
      height: 200px;
      background-size: cover;
      background-position: center;
      max-width: initial;
    }
  }
}