/*
|--------------------
|       Contact
|--------------------
*/

#page-contact {
  h2 {
    font-size: 38px;
  }

  .coordonnee {
    .coor {
      display: block;
      color: #333333;
      font-family: "Work Sans";
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      display: block;
      margin-right: 20px;

      img {
        height: 40px;
      }
    }
    .email-ig {
      width: 220px;
    }
  }

  form {
    .top_label {
      margin: 0;
    }
    .gfield {
      margin-bottom: 15px;
    }
    .gfield_required {
      color: $blue;
      font-size: 24px;
    }

    label {
      color: #333333;
      font-family: "Cinzel";
      font-size: 24px;
      font-weight: 400;
    }

    input[type="text"] {
      border: none;
      padding: 0;
      border-bottom: 1px solid $blue;
    }

    textarea {
      height: 260px;
      padding: 20px;
      border: none;
      border: 1px solid $blue;

      &::placeholder {
        color: #333333;
        font-family: "Cinzel";
        font-size: 24px;
        font-weight: 400;
      }
    }

    .gfield_validation_message {
      padding: 5px 10px;
      margin: -1px 0 0px 0;
    }

    .gfield_consent_label {
      color: #333333;
      font-family: "Work Sans";
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 24px;
      margin-bottom: 0;

      a {
        text-decoration: underline;
      }
    }

    .ginput_container_consent {
      color: #333333;
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      letter-spacing: normal;
      line-height: 24px;
      text-align: left;
      font-weight: 400;
      position: relative;

      a {
        text-decoration: underline;
      }

      .gfield_required {
        display: none;
      }

      input[type="checkbox"] {
        position: absolute;
        cursor: pointer;
        border: none;
        left: 0;
        top: 5px;
        padding: 0;
        margin: 0;
      }

      input[type="checkbox"]:before {
        content: "";
        display: block;
        position: absolute;
        width: 22px;
        height: 22px;
        top: 0;
        left: 0;
        border: 1px solid $blue;
        background-color: transparent;
      }

      input[type="checkbox"]:checked:after {
        content: "";
        display: block;
        width: 9px;
        height: 14px;
        border: solid $blue;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 2px;
        left: 6px;
      }

      label {
        margin-left: 40px;
      }
    }
  }
  .gfield_label {
    padding: 0;
    position: absolute;
  }

  .gform_validation_errors {
    margin-bottom: 30px;
    border-radius: 0px;

    .gform_submission_error {
      font-size: 15px !important;
      font-family: "Work Sans" !important;
    }
  }
  .btn {
    float: left;
  }
}
