/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
  padding: 50px 0;
  @include media-breakpoint-down(md) {
    text-align: center;
  }

  h3 {
    @include media-breakpoint-down(md) {
        text-align: center!important;
    }
    a {
      font-size: 16px;
      color: #276f8b;
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
  }

  a {
    font-size: 15px;

    &:hover {
      color: $blue;
      transition: all ease 0.3s;
    }
  }

  .logo-ft {
    height: 60px;
    display: block;
    max-width: initial;
    margin-bottom: 30px;
    @include media-breakpoint-down(md) {
      margin: auto;
    }
  }

  ul {
    li {
      padding-bottom: 15px;
    }
  }

  .rs {
    border: 1px solid #fff;
    border-radius: 50%;

    &:hover {
      border: 1px solid #a8d0e0;
      transition: all ease 0.5s;
    }
  }

  .copy {
    position: relative;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    hr {
      background-color: $blue;
      height: 1px;
      border: none;
      margin: 12px 0;
      opacity: 0.2;
    }
  }

  #ot-sdk-btn {
    color: initial;
    border: none;
    padding: 0;
    background: none;
    font-size: 15px;
    font-family: "Work Sans";
  }

  .img-ad {
    height: 32px;
  }
}