/*
|--------------------
|      HEADER
|--------------------
*/

/*
|
| Header
|---------
*/
.link-menu {
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

#header {
  width: 100%;
  position: absolute;
  z-index: 10;

  .header-container {
    @extend .container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 25px;


    .item-logo {
      display: block;

      img {
        max-height: 56px;
      }
    }

    .item-logo-c {
      display: none;

      img {
        max-height: 56px;
      }
    }

    .item-nav {
      display: none;

      .item-menu {
        display: flex;

        li {
          &:not(:last-child) {
            margin-right: 20px;
          }

          .item-link {
            color: #ffffff;
            font-family: "Cinzel";
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: left;
            padding: 0 20px;
          }
        }
      }

      .menu-item {
        .nav-drop {
          position: absolute;
          background: #fff;
          width: max-content;
          padding: 20px;
          top: 30px;
          max-height: 0;
          display: block;
          overflow: hidden;
          opacity: 0;
          min-width: 182px;
          transition: all 0.4s;
          box-shadow: 0px 0px 6px #eee;

          li {
            margin: initial;

            &:not(:last-child) {
              padding-bottom: 10px;
            }

            a {
              color: #000;
              font-family: "Cinzel";
              font-size: 16px;
              font-weight: 300;
              font-style: normal;
              letter-spacing: normal;
              line-height: 22px;
              text-align: center;

              &:hover {
                color: $blue;
              }
            }
          }
        }

        &:hover {
          .nav-drop {
            max-height: 300px;
            opacity: 1;
          }
        }
      }

      @include media-breakpoint-up(xl) {
        display: flex;
        align-items: center;

        .item-menu {
          margin-right: 25px;
        }
      }
    }
  }

  .lang-container {
    position: absolute;
    display: inline-flex;
    top: 17px;
    right: 15px;
    cursor: pointer;
    color: #ffffff;
    font-family: "Cinzel";
    font-size: 16px;
    font-weight: 500;
    @include media-breakpoint-down(lg) {
      right: 55px;
    }

    ul {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateX(10px);
      transition: opacity 1.2s $easeSmooth, transform 2s $easeSmooth;
      font-family: "Cinzel";
      font-size: 16px;
      font-weight: 500;

      li {
        a {
          color: #ffffff;
        }
      }
    }

    &:hover {
      ul {
        opacity: 1 !important;
        visibility: visible !important;
        transform: translateX(0) !important;
        font-family: "Cinzel";
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .btn-menu {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 19px;
    float: right;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    & > .item-burger {
      display: block;
      width: 25px;

      & > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: #e8e8e8;
        border-radius: 2px;

        &:nth-child(2) {
          margin: 6px 0;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

}

/*
|
| Menu
|-------
*/
#mobile-menu {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  overflow: auto;
  opacity: 0;
  visibility: hidden;

  .item-container {
    @extend .container;
    //padding-top: 150px;
    //padding-bottom: 50px;

    ul {
      & > li {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        a {
          text-transform: uppercase;
          color: #000;
          font-size: 30px;
          font-weight: 500;
          -webkit-font-smoothing: antialiased;

          @include media-breakpoint-down(xs) {
            font-size: 26px;
          }
        }
      }
    }
  }

  .item-socials-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    .item-socials {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      a {
        font-size: 25px;

        &:not(:last-child) {
          margin-right: 25px;
        }
      }

      @include media-breakpoint-down(xs) {
        margin-bottom: 15px;

        a {
          font-size: 20px;
        }
      }
    }
  }

  .nav-drop {
    padding: 20px 0px 8px 20px;

    li {
      &:not(:last-child) {
        margin-bottom: 15px;
      }

      a {
        font-size: 22px !important;
      }
    }
  }
}