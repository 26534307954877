/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  color: #333333;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  @each $property, $value in $default-titles {
    #{$property}: $value;
  }
}

h1 {
  color: #ffffff;
  font-family: "Cinzel";
  font-size: 56px;
  line-height: 84px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  @include media-breakpoint-down(md) {
    font-size: 42px;
    line-height: 48px;
  }
}

h2 {
  color: $blue;
  font-family: "Cinzel";
  font-size: 40px;
  line-height: 48px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  @include media-breakpoint-down(md) {
    font-size: 32px;
    line-height: 38px;
  }
}

h3 {
  color: $blue;
  font-family: "Cinzel";
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  @include media-breakpoint-down(md) {
    font-size: 20px;
    line-height: 26px;
  }
}

button, input, textarea {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
}

img {
  max-width: inherit;
}

a, img, span, button {
  display: inline-block;
}

a {
  color: $default-link-color;
  text-decoration: none;

  &:hover {
    color: $default-link-color;
  }
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}


.flex {
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.txt-c {
  text-align: center;
}

.m-a {
  margin: auto;
  display: table;
}

.lg-m-a {
  @include media-breakpoint-down(md) {
    margin: auto;
    display: table;
  }
}

.o-h {
  overflow: hidden;
}

.mg-c {
  margin: auto;
  display: table;
}

.dis-desk {
  display: block;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.dis-mob {
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
}

.center {
  margin: auto;
  display: block;
}

.rel {
  position: relative;
}

.clearfix {
  clear: both;
  width: 100%;
}