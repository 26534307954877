/*
|--------------------
|      CONTENT
|--------------------
*/

/*
|
| Banner
|---------
*/
.banner {
  @extend .bg-cover;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 600px;
  background-color: #000000;
  color: #fff;
  position: relative;
  &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.2;
    background-color: #000;
  }

  .item-content {
    width: 100%;
    padding: 100px 0;
  }
}


/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {

  .custom-card-link {
    display: block;
  }

  /*
  * Card img
  */
  .card-img-container {
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    .card-img {
      position: absolute;

      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      transition: transform 2s $easeOutExpo;
      will-change: transform;
    }
  }

  /*
  * Card content
  */
  .card-content {

    .date {
      color: rgba($very-dark-grey, 0.6);
    }
  }

  /*
  * Hover state
  */
  &:hover {

    .card-img-container {

      .card-img {
        transform: scale(1.05);
      }
    }
  }


}


/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
  display: none !important;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;

  .item-close {
    font-size: 24px;
    cursor: pointer;
  }


  @include media-breakpoint-down(sm) {
    padding: 20px 0;
    font-size: 12px;
  }

  @include media-breakpoint-down(xs) {
    padding: 10px 0 20px 0;

    .item-close {
      font-size: 18px;
    }
  }
}


/*
|
| Pagination
|---------------
*/
.pagination-container {
  li {
    display: inline-block;

    a {
      transition: all 0.3s ease-out;
      padding: 0 8px;

      &:hover {
        color: $grey;
      }
    }

    .current {
      padding: 0 8px;
      font-weight: 800;
      color: $grey;
    }
  }
}


/*
|
| CMS
|------
*/
.cms {
  span {
    display: inline;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  strong {
    font-weight: 800;
  }

  p {
    margin-bottom: 20px;
  }

  &.cms-no-break {
    p {
      margin-bottom: 0;
    }
  }

  blockquote {
    position: relative;

    width: 80%;

    margin-right: 0;
    margin-left: auto;
    padding: 130px 0;

    font-size: 30px;
    line-height: 1.5em;
    font-weight: 300;

    @include media-breakpoint-down(md) {
      margin: auto;
    }

    &:before {
      content: "";

      position: absolute;

      background-image: url('../img/icon/quote-left.svg');

      width: 50px;
      height: 50px;


      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transform: translateX(-60px) translateY(-30px);
    }

    &:after {
      content: "";

      position: absolute;
      right: 0;

      background-image: url('../img/icon/quote-right.svg');

      width: 50px;
      height: 50px;


      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transform: translateX(0) translateY(-50px);

      @include media-breakpoint-down(md) {
        transform: translateX(60px) translateY(-50px);
      }
    }
  }

  ul {
    margin-left: 18px;
    margin-top: 10px;
    margin-bottom: 10px;

    li {
      list-style-type: initial;
    }
  }
}


/*
|
| Page offset
|--------------
*/
.page-offset {
  padding-top: $headerHeight;

  @include media-breakpoint-down(lg) {
    padding-top: $headerHeightSm;
  }
}


/*
|
| Custom Loader
|---------------
*/
.custom-loader {
  width: 100px;
  display: inline-block;

  &.cl-center {
    margin-left: auto;
    margin-right: auto;
  }

  svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: $black;

    circle {
      stroke: $black;
    }
  }

  &.ajax-loader {
    display: none;
  }
}

.questions {
  height: 570px;
  width: 100%;
  background-size: cover;
  background-position: center;

  .flex {
    height: 570px;

    .title {
      color: #ffffff;
      font-family: "Cinzel";
      font-size: 40px;
      line-height: initial;
      font-weight: 400;
      text-align: center;
      @include media-breakpoint-down(sm) {
        font-size: 26px;
      }
    }

    .text {
      color: #ffffff;
      font-family: "Work Sans";
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
    }

    .btn {
      display: table;
      margin: auto;
    }
  }
}

#expertises {
  .intro {
    color: #333333;
    font-family: "Work Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  .expertises {
    overflow: hidden;

    .bloc-expertise {
      z-index: 1;
      position: relative;
      min-height: 100px;
      margin-bottom: 38px;
      transition: all ease 0.4s;

      &.item-1 {
        .bloc-expert {
          top: 0px;
          clip: rect(0px, 555px, 100px, 0px);
          @include media-breakpoint-down(sm) {
            top: initial;
            clip: initial;
            position: initial;
          }
        }
      }

      &.item-2 {
        .bloc-expert {
          top: -138px;
          clip: rect(138px, 555px, 238px, 0px);
          @include media-breakpoint-down(sm) {
            top: initial;
            clip: initial;
            position: initial;
          }
        }
      }

      &.item-3 {
        .bloc-expert {
          top: -276px;
          clip: rect(276px, 555px, 376px, 0px);
          @include media-breakpoint-down(sm) {
            top: initial;
            clip: initial;
            position: initial;
          }
        }
      }

      .title-expert {
        opacity: 0.7;
        color: #333333;
        cursor: pointer;
        min-height: 100px;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      .bloc-expert {
        background-size: cover;
        background-position: center;
        color: #ffffff;
        font-family: "Work Sans";
        font-size: 15px;
        font-weight: 300;
        height: 375px;
        padding: 30px;
        position: absolute;
        transition: all ease 0.4s;
        @include media-breakpoint-down(sm) {
          mask: none;
          height: initial;
        }

        &:before {
          content: "";
          position: absolute;
          background-color: #000;
          opacity: 0.5;
          left: 0;
          right: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
        }

        .bloc-content {
          opacity: 0;
          transition: all ease 0.4s;
          z-index: 1;
          @include media-breakpoint-down(sm) {
            opacity: 1;
          }

          .mark {
            clip: rect(0px, 555px, 100px, 0px);
          }

          .desc {
            width: 80%;
            z-index: 2;
            @include media-breakpoint-down(sm) {
              width: 100%;
            }
          }

          .title {
            color: #ffffff;
            font-family: "Cinzel";
            font-size: 24px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: left;
            z-index: 2;
          }
        }
      }

      &.active, &:hover {
        z-index: 10;
        transition: all ease 0.4s;

        .title-expert {
          color: $blue;
        }

        .bloc-expert {
          opacity: 1;
          height: 375px;
          transition: all ease 0.4s;
          clip: rect(0px, 555px, 375px, 0px);

          .bloc-content {
            opacity: 1;
            transition: all ease 0.4s;
          }
        }
      }
    }
  }
}

.btn {
  color: #d2d2d2;
  font-family: "Cinzel";
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  background: none;
  border: none;
  position: relative;
  padding: 23px 50px 15px 50px;

  .btn1 {
    display: block;
    border: 1px solid #a5a5a5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:before {
      content: "";
      background-color: #fff;
      height: 1px;
      width: 0px;
      display: block;
      position: absolute;
      top: 4px;
      left: 4px;
      z-index: 1;
      transition: all ease 0.3s;
    }

    &:after {
      content: "";
      background-color: #fff;
      height: 0px;
      width: 1px;
      display: block;
      position: absolute;
      top: 4px;
      left: 4px;
      z-index: 1;
      transition: all ease 0.3s;
    }
  }

  .btn2 {
    display: block;
    border: 1px solid #a5a5a5;
    position: absolute;
    top: 5px;
    left: 5px;
    right: -5px;
    bottom: -5px;

    &:before {
      content: "";
      background-color: #fff;
      height: 1px;
      width: 0px;
      display: block;
      position: absolute;
      bottom: 4px;
      right: 4px;
      z-index: 1;
      transition: all ease 0.3s;
    }

    &:after {
      content: "";
      background-color: #fff;
      height: 0px;
      width: 1px;
      display: block;
      position: absolute;
      bottom: 4px;
      right: 4px;
      z-index: 1;
      transition: all ease 0.3s;
    }
  }

  &:hover {
    color: #fff;
    transform: scale(1.03) !important;

    .btn1 {
      &:before {
        content: "";
        width: calc(100% + 2px);
        transition: all ease 0.3s;
      }

      &:after {
        content: "";
        height: calc(100% + 2px);
        transition: all ease 0.3s;
      }
    }

    .btn2 {
      &:before {
        content: "";
        width: calc(100% + 2px);
        transition: all ease 0.3s;
      }

      &:after {
        content: "";
        height: calc(100% + 2px);
        transition: all ease 0.3s;
      }
    }
  }

  &.btn-b {
    color: #333;

    .btn1, .btn2 {
      border: 1px solid #bfd4dc;
    }

    &:hover {
      .btn1, .btn2 {
        &:before {
          content: "";
          background-color: #333;
        }

        &:after {
          content: "";
          background-color: #333;
        }
      }
    }
  }
}


#encart {
  background-color: #e3f0ee;
  opacity: 0.7;
  color: $blue;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  img {
    height: 45px;
  }

  hr {
    border: none;
    width: 100%;
    height: 1px;
    margin: 10px 0 20px 0;
    background-color: #9fd4db;
  }
}

.home{
  #encart {
    img{
      height: 26px;
    }
  }
}

.imgtxt {
  .img {
    height: 750px;
    width: 100%;
    background-size: cover;
    background-position: center;
    @include media-breakpoint-down(md) {
      height: 440px;
      max-width: 340px;
      margin: auto;
    }
  }

  p {
    padding-bottom: 15px;
  }

  ul {
    margin-left: 18px;
    margin-top: 10px;
    margin-bottom: 10px;

    li {
      list-style-type: none;
      min-height: 50px;
      margin-bottom: 10px;
      padding-top: 8px;
      margin-left: 50px;
      position: relative;

      &:before {
        content: "";
        background-image: url("../img/icon/puce.svg");
        background-repeat: no-repeat;
        height: 40px;
        width: 40px;
        display: block;
        margin-right: 12px;
        position: absolute;
        left: -50px;
        top: 0px;
      }
    }
  }
}

.centered {
  float: none;
  margin: 0 auto;
}

.iziModal {
  border-radius: 0 !important;
  max-width: 70% !important;
  background-color: #fff !important;
  @include media-breakpoint-down(md) {
    max-width: 95% !important;
  }

  .iziModal-content {
    padding: 40px 60px !important;
    @include media-breakpoint-down(md) {
      padding: 20px 0px !important;
    }

    img {
      display: block;
      min-width: 270px;
      @include media-breakpoint-down(md) {
        max-width: fit-content;
        height: 300px;
        width: initial;
      }
    }
  }

  .close {
    position: absolute;
    top: 12px;
    right: 10px;
    cursor: pointer;
    z-index: 1;

    img {
      height: 20px!important;
      min-width: inherit;
    }
  }

  p {
    color: #03003f;
    font-family: "Graphik";
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.13px;
    line-height: 28px;
    text-align: left;

    strong {
      font-family: "Graphik";
      font-weight: 400;
    }
  }

  h2 {
    text-align: left;
    font-size: 24px;
    font-family: "Sang bleu";
  }

  ul, ol {
    margin-top: 40px;
    margin-bottom: 30px;

    li {
      font-family: "Graphik";
      font-size: 18px;
      font-weight: 400;
      position: relative;
      display: block;
      margin-top: 7px;
      margin-bottom: 13px;

      &:before {
        content: "";
        width: 11px;
        height: 14px;
        margin-right: 15px;
        margin-top: 8px;
        display: block;
        float: left;
      }
    }
  }
  .photo-modal{
    height: 260px;
    background-repeat: no-repeat;
    background-size: contain;
  }
}