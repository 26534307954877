/*
|--------------------
|     PAGE ABOUT
|--------------------
*/

#a-propos {
  .desc {
    position: relative;

    &:before {
      content: "";
      background-image: url('../img/icon/quotes.svg');
      background-repeat: no-repeat;
      height: 27px;
      width: 40px;
      position: absolute;
      top: -12px;
      left: -17px;
      @include media-breakpoint-down(sm) {
        top: -10px;
        left: -7px;
        opacity: 0.7;
      }
    }
  }
}

#chiffres-cles {
  background-color: $blue;
  color: #fff;
  position: relative;

  &:before {
    content: "";
    background-size: cover;
    background-image: url('../img/icon/letter-a.svg');
    width: 310px;
    height: 316px;
    position: absolute;
    bottom: -6px;
    @include media-breakpoint-down(lg) {
      width: 378px;
      height: 369px;
    }
    @include media-breakpoint-down(md) {
      opacity: 0.16;
    }
    @include media-breakpoint-down(sm) {
      width: 248px;
      height: 240px;
    }
  }

  h2 {
    color: #fff;
  }

  .chiffre {
    .unity {
      color: #ffffff;
      font-family: "Cinzel";
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
    }

    .desc {
      font-size: 40px;
    }

    hr {
      margin: 10px 0 20px 0;
      border: 1px solid #fff;
    }
  }
}

#team,
#comite {
  h3 {
    font-size: 16px;
    @include media-breakpoint-down(lg) {
      font-size: 13px;
    }
    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  .elem {
    cursor: pointer;

    .photo {
      height: 360px;
      background-size: cover;
      background-position: center;
      position: relative;
      overflow: hidden;
      @include media-breakpoint-down(lg) {
        height: 350px;
      }

      &:before {
        content: "";
        background-color: $blue;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: all ease 0.4s;
      }

      .loupe {
        opacity: 0;
        transition: all ease 0.4s;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        bottom: 0;
        height: 30px;
      }

      .text {
        color: #ffffff;
        font-family: "Work Sans";
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        padding: 8px;
        position: absolute;
        bottom: 0;
        opacity: 0;
        transition: all ease 0.4s;
      }
    }

    .photo-mob {
      max-width: 180px;
    }

    &:hover {
      .photo {
        &:before {
          content: "";
          opacity: 0.7;
          transition: all ease 0.4s;
        }

        .loupe {
          opacity: 1;
          transition: all ease 0.4s;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          top: 0;
          bottom: 0;
          height: 30px;
        }

        .text {
          opacity: 1;
          transition: all ease 0.4s;
        }
      }
    }
  }
}