/*
|--------------------
|     PAGE HOME
|--------------------
*/

.home {
  #header {
    position: absolute;

    .item-logo {
      display: none;
      opacity: 0;
      transition: all ease 1s;
    }

    .item-logo-c {
      display: block;
      opacity: 1;
      transition: all ease 1s;
    }

    .item-link {
      color: #000000 !important;
    }
  }

  .titlehome {
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    color: #000;
    opacity: 1;
    transition: all ease 0.4s;

    span {
      color: #000000;
      font-family: "Cinzel";
      font-size: 56px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      @include media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }

  .banner {
    min-height: 100vh;
    clip-path: polygon(10% 80%, 90% 80%, 90% 100%, 10% 100%);
    transition: all ease 1s;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.2;
      background-color: #000;
    }

    h1 {
      display: none;
      opacity: 0;
      transition: all ease 0.4s;
      @include media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }

  .lang-container {
    color: #000 !important;

    .lang-item, li a, span {
      color: #000 !important;
      transition: all ease 1s;
    }
  }
  .item-burger {
    span {
      background-color: #000 !important;
      transition: all ease 0.4s;
    }
  }
  &.active {
    #header {
      .item-logo {
        display: block;
        opacity: 1;
        transition: all ease 0.4s;
      }

      .item-logo-c {
        display: none;
        opacity: 0;
        transition: all ease 1s;
      }

      .item-link {
        color: #fff !important;
        transition: all ease 1s;
      }
    }

    .banner {
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
      transition: all ease 1s;

      h1 {
        display: block;
        opacity: 1;
        transition: all ease 0.4s;
      }
    }

    .titlehome {
      opacity: 0;
      transition: all ease 0.4s;
    }

    .item-burger {
      span {
        background-color: #e8e8e8 !important;
        transition: all ease 0.4s;
      }
    }

    .lang-container {
      .lang-item, li a {
        color: #fff !important;
        transition: all ease 1s;
      }
    }

    .btn-menu {
      & > .item-burger {
        & > span {
          background-color: #fff;
        }
      }
    }
  }

  #references {
    .ref {
      .img {
        width: 100%;
        height: 200px;
        background-color: #f6f6f6;

        img {
          margin: auto;
          max-height: 60px;
          transition: all ease 0.4s;
        }
      }

      &:hover {
        img {
          transform: scale(1.12);
          transition: all ease 0.4s;
        }
      }
    }
  }
}
