/*
|--------------------
|       404
|--------------------
*/

#page-404 {
  background-size: cover;
  .section-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    color: $white;
    text-align: center;

    .item-title {
      font-size: 30px;
      font-weight: 300;
      line-height: 1;
    }
    .item-text {
      font-size: 18px;
      font-weight: 300;
      line-height: 1;
      a{
        color: $white;
        text-decoration: underline;
      }
    }
  }
}
